var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"types"},[_c('Slick',{attrs:{"dots":true,"arrows":false,"responsive":[
      {
        breakpoint: 10000000,
        settings: 'unslick'
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}},_vm._l((_vm.types),function(type,index){return _c('div',{key:index,staticClass:"types__item type"},[_c('div',{staticClass:"type__content"},[_c('h3',{staticClass:"type__title mobile"},[_vm._v(" "+_vm._s(type.name)+" ")]),_c('Picture',{staticClass:"type__pic",attrs:{"preview":type.prev,"picture":type.pic,"description":"Посмотреть образец"}}),_c('div',{staticClass:"type__info"},[_c('h3',{staticClass:"type__title tablet desktop"},[_vm._v(" "+_vm._s(type.name)+" ")]),_c('ul',{staticClass:"type__service-list"},_vm._l((type.serviceList),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{staticClass:"type__bottom"},[_c('Button',{attrs:{"small":""},nativeOn:{"click":function($event){return _vm.order.apply(null, arguments)}}},[_vm._v(" Заказать ")]),_c('p',{staticClass:"type__price"},[_vm._v("Стоимость "+_vm._s(type.price)+" ₽")])],1)])],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }