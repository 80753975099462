<template>
  <div class="types">
    <Slick
      :dots="true"
      :arrows="false"
      :responsive="[
        {
          breakpoint: 10000000,
          settings: 'unslick'
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]"
    >
      <div v-for="(type, index) in types" :key="index" class="types__item type">
        <div class="type__content">
          <h3 class="type__title mobile">
            {{ type.name }}
          </h3>
          <Picture
            class="type__pic"
            :preview="type.prev"
            :picture="type.pic"
            description="Посмотреть образец"
          />
          <div class="type__info">
            <h3 class="type__title tablet desktop">
              {{ type.name }}
            </h3>
            <ul class="type__service-list">
              <li v-for="(item, i) in type.serviceList" :key="i">
                {{ item }}
              </li>
            </ul>
            <div class="type__bottom">
              <Button small @click.native="order">
                Заказать
              </Button>
              <p class="type__price">Стоимость {{ type.price }} ₽</p>
            </div>
          </div>
        </div>
      </div>
    </Slick>
  </div>
</template>

<script>
import './types.scss';

export default {
  name: 'Types',
  components: {
    Picture: () => import('@/components/gui/picture/Picture'),
    Button: () => import('@/components/gui/button/Button')
  },
  data() {
    return {
      types: [
        {
          name: 'Формирование отчета по данным из ЕГРН на объект недвижимости',
          serviceList: [
            'Площадь и этаж объекта',
            'Дата ввода в эксплуатацию',
            'Справка о правопритязаниях на недвижимое имущество',
            'Информация о текущем собственнике',
            'Подтверждение или опровержение залоговых обременений',
            'Наличие запретов на перерегистрацию',
            'Кадастровая стоимость недвижимости'
          ],
          price: 150,
          prev: require('./examples/params.png'),
          pic: require('./examples/params.png')
        },
        {
          name:
            'Формирование отчета по данным из ЕГРН о переходе прав об объект недвижимости',
          serviceList: [
            'Полная история собственников',
            'Даты регистрации прав собственности',
            'Даты прекращения прав',
            'Данные текущего собственника'
          ],
          price: 150,
          prev: require('./examples/rights.png'),
          pic: require('./examples/rights.png')
        },
        {
          name:
            'Формирование отчета по данным из ЕГРН о кадастровой стоимости объекта недвижимости',
          serviceList: [
            'Кадастровая стоимость',
            'Документ основания (при наличии)'
          ],
          price: 150,
          prev: require('./examples/property.png'),
          pic: require('./examples/property.png')
        }
      ]
    };
  },
  methods: {
    order() {
      document.querySelector('#address input').focus();
    }
  }
};
</script>
